import { useParams, usePathname, useSearchParams } from "next/navigation";
import { Dropdown, DropdownProps } from "antd";
import { FaChevronDown } from "react-icons/fa";
import classNames from "classnames";

// helpers
import global from "@/api/query/global";

// interfaces
import { IHeaderRegionSelector } from "./HeaderRegionSelector.interface";

// assets
import styles from "@/assets/styles/components/header-dropdown.module.scss";

const HeaderRegionSelector = ({ onRegionChange, className, isAuthHeader }: IHeaderRegionSelector) => {
  const { lang } = useParams();
  const pathname = usePathname()
  const searchParams = useSearchParams();

  const { data: regions } = global.useGeographicRegion();
  const regionOptions = (regions || []).map(item => ({
    label: item.name,
    value: `/${item.code}`,
    code: item.code,
  }));


  const containerClassName = classNames(
    styles["locl-header-dropdown"],
    styles["locl-header-dropdown__region"],
    { [styles["locl-header-dropdown__auth"]]: isAuthHeader },
    className
  );

  const currentRegion = regionOptions.find((region) => region.code === lang) || regionOptions[0];
  const currentUrl = pathname.replace(/^\/(uk|aus)/, "") || "/";

  // renders
  const menuItems = regionOptions.map(item => {
    const regex = /^\/collection/;

    const hrefForLink = (regex.test(currentUrl) && item.code === "aus")
      ? process.env.NEXT_PUBLIC_LOLIVE_URL
      : `${process.env.NEXT_PUBLIC_LOLIVE_URL}${item.value}${currentUrl}${searchParams.toString() ? `?${searchParams.toString()}` : ""}`

    return ({
      label: (
        <a
          href={hrefForLink}
          onClick={() => onRegionChange && onRegionChange()}
          key={item.label}
        >
          {item.label}
        </a>
      ),
    });
  });

  return (
    <div className={containerClassName}>
      <Dropdown
        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
        overlayClassName={styles["locl-header-dropdown__dropdown"]}
        menu={{ items: menuItems } as DropdownProps["menu"]}
        trigger={["click"]}
        placement={"bottom"}
      >
        <div className={styles["locl-header-dropdown__button"]}>
          <div className={styles["locl-header-dropdown__button-text"]}>{currentRegion.label}</div>
          <div className={styles["locl-header-dropdown__button-icon"]}><FaChevronDown className="locl-hirer-header-dropdown__value-arrow_icon"/></div>
        </div>
      </Dropdown>
    </div>
  );
};

export default HeaderRegionSelector;
